import { Component, OnInit } from "@angular/core";
import { BuilderStateService } from "@builder-services/core/builder-state.service";
import { Subscription } from "rxjs";
import { ToastService } from "../../toast/toast.service";

@Component({
    selector: "app-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
})
export class ToastComponent implements OnInit {
    message = "";
    private subscriptions: Subscription = new Subscription();

    constructor(
        private toastService: ToastService,
        private stateService: BuilderStateService
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.toastService.toastState$.subscribe((message) => {
                this.message = message;
            })
        );

        this.subscriptions.add(
            this.stateService.$sideMenuOpened.subscribe((isOpened) => {
                if (!isOpened) {
                    this.closeToast();
                }
            })
        );

    }

    closeToast() {
        this.message = "";
    }
}
