import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Cookie } from "ng2-cookies";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import { catchError, map, tap } from "rxjs/operators";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { Credentials } from "../../features/login/models/credentials";
import { environment } from "../../../environments/environment";
import { Error } from "../../features/account-status/models/Error";

@Injectable()
export class AuthService {
    error: Error;
    private readonly loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private readonly isFirstLoginSubject = new BehaviorSubject<boolean>(false);
    private refreshTokenTimeout: string | number | NodeJS.Timeout;
    tokenCheckInterval: string | number | NodeJS.Timeout;
    isFirstLogin$: Observable<boolean>;

    constructor(
        private readonly localStorageService: LocalStorageService,
        private readonly http: HttpClient,
        private readonly router: Router
    ) {
        this.isFirstLogin$ = this.isFirstLoginSubject.asObservable();
    }

    get isLoggedIn() {
        const token = this.getToken();
        this.loggedIn.next(token !== null && !this.tokenExpired(token));
        return this.loggedIn.asObservable();
    }

    tokenExpired(token: string): boolean {
        const helper = new JwtHelperService();
        return helper.isTokenExpired(token);
    }

    getTokenExpirationDate(token: string) {
        const helper = new JwtHelperService();
        return helper.getTokenExpirationDate(token);
    }

    getToken(): string {
        return Cookie.get(environment.cookies.brame_at);
    }

    getRefreshToken(): string {
        return Cookie.get(environment.cookies.refresh_brame_at);
    }

    setCookie(name: string, value: string): void {
        Cookie.set(name, value, 14, "/");
    }

    deleteCookie(name: string): void {
        Cookie.delete(name, "/");
    }

    logout(): void {
        this.deleteCookie(environment.cookies.brame_at);
        this.deleteCookie(environment.cookies.refresh_brame_at);
        this.localStorageService.removeItem(environment.contextKeyName);
        this.localStorageService.removeItem(LocalStorageKeys.USER_TYPE);
        this.localStorageService.removeItem(LocalStorageKeys.USER_ROLE);
        this.stopRefreshTokenTimer();
        if (this.tokenCheckInterval) {
            clearInterval(Number(this.tokenCheckInterval));
        }
    }

    checkTokenExpiration() {
        this.tokenCheckInterval =  setInterval(() =>{
            const token = this.getToken();
            if (!token || this.tokenExpired(token)) {
                this.logout();
                void this.router.navigate(["/login"]);
            }
        }, 60 * 1000);
    }

    otpLogin(credentials: Credentials): Observable<any> {
        this.logout();
        const data: any = {
            username: credentials.username,
            password: credentials.password,
            grantType: "password",
        };

        if (credentials.otpCode) {
            data.otpCode = credentials.otpCode;
        }

        return this.http
            .post(environment.apiUrlPrefix + "/user-management/users/log-in", data)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.handleError(response);
                    return of(response);
                }),
                tap((response) => {
                    if (!!response.firstLogin) {
                        this.isFirstLoginSubject.next(true);
                    }

                    return of(response);
                })
            );
    }

    handleTokenRefresh() {
        const body = {
            refreshToken: this.getRefreshToken(),
        };
        return this.http.post(environment.apiUrlPrefix + "/user-management/users/refresh-token",
            body,
            {
                headers: new HttpHeaders().set(
                    "Content-Type",
                    "application/json"
                ),
            }
        )
            .pipe(
                map(
                    (response: any) => {
                        const newAccessToken = response.data.accessToken;
                        const newRefreshToken = response.data.refreshToken;

                        this.refreshCookies({access_token: newAccessToken, refresh_token: newRefreshToken});
                    }
                ),
                catchError((response) => {
                    this.logout();
                    void this.router.navigate(["/login"]);
                    return of(response);
                })
            );
    }

    startRefreshTokenTimer() {
        const token = this.getToken();
        const expires = this.getTokenExpirationDate(token);
        const timeout = expires.getTime() - Date.now() - 5 * 60 * 1000;

        if (this.refreshTokenTimeout) {
            clearTimeout(Number(this.refreshTokenTimeout));
        }

        if (timeout > 0) {
            this.refreshTokenTimeout = setTimeout(() => {
                this.handleTokenRefresh().subscribe();
            }, timeout);
        } else {
            this.handleTokenRefresh().subscribe();
        }
    }

    checkOTPConfiguration(email: string) {
        return this.http
            .get(`${environment.apiUrlPrefix}/user-management/users/check-otp/${email}`)
            .pipe(
                catchError(() => of(false)),
                map(
                    (response: { data: boolean; message: string }) =>
                        response.data
                )
            );
    }

    private stopRefreshTokenTimer() {
        clearTimeout(Number(this.refreshTokenTimeout));
    }

    private handleError(response: HttpErrorResponse) {
        const { status, error } = response;
        this.error = {
            status,
            message:
                (error &&
                    error.violations &&
                    error.violations.length &&
                    error.violations[0].message) ||
                "",
        };
    }

    private refreshCookies(response: { access_token: string; refresh_token: string }) {
        this.deleteCookie(environment.cookies.brame_at);
        this.deleteCookie(environment.cookies.refresh_brame_at);
        this.setCookie(
            environment.cookies.brame_at,
            response.access_token
        );
        this.setCookie(
            environment.cookies.refresh_brame_at,
            response.refresh_token
        );
    }
}
