import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ResponseDTO } from "@models/ResponseDTO";
import { CampaignMetadata } from "@models/CampaignMetadata";
import { CampaignStatus, CampaignStatusFactory } from "@models/CampaignStatus";
import { UtilService } from "src/app/core/util-services/util.service";
import {LocalStorageKeys} from "@models/LocalStorageKeys";
import {
    Column,
    FilterType,
} from "../../shared/components/table/models/Column";
import {LocalStorageService} from "../util-services/local-storage.service";

interface Params {
    companyId?: string;
    status?: string;
    page?: number;
    size?: number;
    name?: string;
    orderBy?: string;
    direction?: string;
    builderVersion?: string;
}

@Injectable({
    providedIn: "root",
})
export class CampaignService {
    constructor(
        private http: HttpClient,
        private util: UtilService,
        private localStorageService: LocalStorageService
    ) {}

    getCampaign(campaignId: string): Observable<CampaignMetadata> {
        return this.getCampaignDetails(campaignId).pipe(map((response: ResponseDTO) => response.data));
    }

    getCampaignDetails(campaignId: string) {
        return this.http.get<ResponseDTO<CampaignMetadata>>(environment.apiUrlPrefix + `/campaign/${campaignId}`);
    }

    searchCampaigns(params: Params) {
        const qs = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(String(params[key]))}`)
            .join("&");
        return this.http.get(environment.apiUrlPrefix + `/campaign/search?${qs}`);
    }

    deleteCampaign(campaignId) {
        return this.http.delete(environment.apiUrlPrefix + `/campaign/${String(campaignId)}`);
    }

    updateCampaign(
        campaignId: string,
        demoUrl: string,
        prodUrl: string,
        logoUrl: string
    ): Observable<any> {
        const campaignData = {
            id: campaignId,
            logoUrl,
            demoUrl,
            productionUrl: prodUrl,
        };

        return this.http.put(environment.apiUrlPrefix + "/campaign/", campaignData);
    }

    updateStatus(campaignId: string, status: CampaignStatus): Observable<any> {
        const campaignData = {
            id: campaignId,
            status,
        };

        return this.http.put(environment.apiUrlPrefix + "/campaign/", campaignData);
    }

    updateCampaignNormally(campaign: CampaignMetadata) {
        return this.http.put(environment.apiUrlPrefix + "/campaign/", campaign);
    }

    exists(campaignId: string): Observable<boolean> {
        return this.http.get(environment.apiUrlPrefix + `/campaign/exists/${campaignId}`) as Observable<boolean>;
    }

    checkCampaignPermission = () => {
        const userRole = this.localStorageService.getItem(LocalStorageKeys.USER_ROLE, []);
        return (
            userRole.some(
                (role) => role === "EDITOR" || role === "SUPER_ADMIN"
            )
        );
    };

    getCampaignColumns(): Column[] {
        const campaignTypeIcons = this.util.getCampaignTypeIcons("shared");
        return [
            {
                title: "Campaign",
                dataProperty: "name",
                sortable: true,
                filterable: true,
                render: (columnData) => (
                    `<div class="br-table-img">
                            <img src="${this.getImageSource(columnData)}" alt="Logo">
                     </div>
                     <span class="br-table-title ml-20 campaign-name" title="${String(columnData.name)}">${String(columnData.name)}</span>`
                ),
                filter: FilterType.SEARCH,
            },
            {
                title: "Type",
                dataProperty: "campaignType",
                sortable: false,
                filterable: false,
                render: (columnData) => (
                    `<div class="campaign-type-img">
                        <div 
                        class="img-wrapper ${(columnData.isTailorMade ? "tailor-made-indicator":"")} "
                        >
                            <img
                            src="${String(campaignTypeIcons[columnData.campaignType])}"
                            alt="${String(columnData.campaignType)}"
                            title="${this.getCampaignTypeTitle(columnData)}">
                        </div>
                    </div>`
                ),
            },
            {
                title: "Status",
                dataProperty: "status",
                sortable: false,
                filterable: true,
                render: (columnData) => (
                    `<span class="${String(columnData.status).toLowerCase()}">${this.util.capitalize(columnData.status)}</span>`
                ),
                filter: FilterType.DROPDOWN,
                filterOptions: CampaignStatusFactory.getDefaultTypes(),
            },
            {
                title: "Date created",
                dataProperty: "createdDate",
                sortable: true,
                filterable: false,
                render: (columnData) => (
                    `<span>${this.setDateCreated(columnData.createdDate)}</span>`
                ),
            },
            {
                title: "Date started",
                dataProperty: "startDate",
                sortable: true,
                filterable: false,
                render: (columnData) => (
                    `<span>${this.setStartDate(columnData.startDate, columnData.status)}</span>`
                ),
            },
            {
                title: "",
                dataProperty: "",
                sortable: false,
                filterable: false,
                btns: [
                    { action: "live", param: "productionUrl" },
                    { action: "edit", param: "id" },
                    { action: "analytics", param: "id" },
                ],
            },
            {
                title: "",
                dataProperty: "",
                sortable: false,
                filterable: false,
                columnClass: "campaign-language-switch",
                customActions: (columnData) => columnData.languages.map((item: any) => ({
                    name: item.name,
                    shortName: item.shortName,
                    show: columnData.status === CampaignStatus.ACTIVE,
                    icon: item.flagImageUrl,
                })),
            },
            {
                title: "",
                dataProperty: "",
                sortable: false,
                filterable: false,
                actions: [
                    {
                        title: "Delete",
                        method: "delete",
                        show: (columnData) => columnData.status !== CampaignStatus.DELETED,
                    },
                    {
                        title: "Deactivate",
                        method: "deactivate",
                        show: (columnData) => columnData.status === CampaignStatus.ACTIVE,
                    },
                    {
                        title: "Unschedule",
                        method: "deactivate",
                        show: (columnData) => columnData.status === CampaignStatus.SCHEDULED,
                    },
                    {
                        title: "Copy",
                        method: "copy",
                        show: () => true,
                    },
                ],
            },
        ];
    }

    getCompanyExpirationDate(companyId: string) {
        return this.http.get(environment.apiUrlPrefix + `/user-management/company-details/expirationDate/${companyId}`);
    }

    getCampaignTypeTitle(columnData) {
        const title = String(columnData.campaignType).replace(/-/g, " ");
        const prefix = "custom made \n";

        if (columnData.isTailorMade) {
            return prefix + title;
        }

        return title;
    }

    getImageSource(columnData) {
        if (columnData.name.toLowerCase().includes("automated")) {
            return "assets/shared/games/no-game.svg";
        }
        return columnData.logoUrl ? String(columnData.logoUrl) : "assets/shared/games/no-game.svg";
    }

    private setStartDate(startDate, status) {
        if (!startDate || status === "SCHEDULED") {
            return "";
        } else {
            return this.util.dateStringToDateTime(this.util.convertToLocalDateTime(startDate).toISOString());
        }
    }

    private setDateCreated(dateCreated) {
        return this.util.dateStringToDateTime(this.util.convertToLocalDateTime(dateCreated).toISOString());
    }
}
