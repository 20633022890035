import {
    CampaignType,
    DefaultCampaignEmailDomain,
    DefaultPennyCampaignEmail,
    PennyCampaign,
} from "@models/CampaignTypes";
import { UserRoles } from "@models/UserRoles";
import { environment } from "src/environments/environment";

export const defaultImage = "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/108-300x200.jpg";

export const WIZARD_PAGE_ACCESS = [UserRoles.EDITOR, UserRoles.SUPER_ADMIN];
export const CAMPAIGNS_PAGES_ACCESS = [UserRoles.VIEWER, UserRoles.EDITOR, UserRoles.SUPER_ADMIN, UserRoles.SUCCESS_MANAGER];
export const PRIZE_MANAGEMENT_PAGES_ACCESS = [UserRoles.EDITOR, UserRoles.SUPER_ADMIN];

export const TSB_COMPANY_ID = "0d8778d3-0420-11ed-b82d-0b1712002074";
export const TESTER_COMPANY_ID = "1613fd96-ac60-11ed-b879-d75c57bf69fc";

// penny campaigns using custom endpoints for analytics data
export const CUSTOM_PENNY_ANALYTICS_CAMPAIGNS = [
    "687b0633-b76b-11ed-b783-f1155093e762",
];

export const DEFAULT_FONT_SIZES = [
    { name: "8 px", value: 8 },
    { name: "9 px", value: 9 },
    { name: "10 px", value: 10 },
    { name: "11 px", value: 11 },
    { name: "12 px", value: 12 },
    { name: "14 px", value: 14 },
    { name: "18 px", value: 18 },
    { name: "24 px", value: 24 },
    { name: "30 px", value: 30 },
    { name: "36 px", value: 36 },
    { name: "48 px", value: 48 },
    { name: "60 px", value: 60 },
];

export const DEFAULT_FONTS = [
    { name: "Montserrat, sans-serif", value: "Montserrat" },
    { name: "Verdana, sans-serif", value: "Verdana" },
    { name: "Georgia, serif", value: "Georgia" },
    { name: "'Courier New', monospace", value: "\"Courier New\"" },
    { name: "'Brush Script MT', cursive", value: "\"Brush Script MT\"" },
    { name: "Garamond, serif", value: "Garamond" },
];

export const GAME_TYPE_ICONS = {
    "memory-game": "/assets/shared/games/memory.svg",
    "guess-the-picture": "/assets/shared/games/gtp.svg",
    "quiz": "/assets/shared/games/quiz.svg",
    "personality-test": "/assets/shared/games/personality.svg",
    "advent-calendar": "/assets/shared/games/advent.svg",
    "easter-calendar": "/assets/shared/games/easter.svg",
    "spin-the-wheel": "/assets/shared/games/wheel.svg",
    "survey": "/assets/shared/games/survey.svg",
    "weekly-calendar": "/assets/shared/games/weekly.svg",
    "scratch-card": "/assets/shared/games/scratch-card.svg",
    "monthly-calendar": "/assets/shared/games/monthly.svg",
    "advanced-spin-the-wheel": "/assets/shared/games/advanced-stw.svg",
    // custom games
    "playable-ad": "assets/shared/games/playable-ad.svg",
    "penny-path": "assets/shared/games/penny-path.svg",
    "penny-spin-the-wheel": "assets/wizard/games/penny-wheel.svg",
    "penny-penalty": "assets/wizard/games/penny-penalty.svg",
    "penny-slot-machine":
        "assets/wizard/games/penny-slot-machine.svg",
    "bipa-spin-the-wheel":
        "assets/wizard/games/bipa-spin-the-wheel.svg",
    // v2 games
    "drop-game": "assets/shared/games/drop.svg",
    "hit-the-target": "assets/shared/games/hit.svg",
    "swim-game": "assets/shared/games/swim.svg",
    "fly-game": "assets/shared/games/fly.svg",
    "drive-game": "assets/shared/games/drive.svg",
    "slot-machine": "assets/shared/games/slot-machine.svg",
    "shell-game": "assets/shared/games/shell.svg",
    "priority-puzzle": "assets/shared/games/priority-puzzle.svg",
    "spin-the-wheel-2.0": "assets/shared/games/spin-the-wheel-2.svg",
    "none": "assets/shared/games/no-game.svg",
};

export const WIZARD_GAME_TYPE_ICONS = {
    "memory-game": "/assets/wizard/games/memory.svg",
    "guess-the-picture-click": "/assets/wizard/games/gtp.svg",
    "quiz-click": "/assets/wizard/games/quiz.svg",
    "quiz-swipe": "/assets/wizard/games/quiz-swipe.svg",
    "personality-test-click": "/assets/wizard/games/personality.svg",
    "personality-test-swipe": "/assets/wizard/games/personality-swipe.svg",
    "advent-calendar": "/assets/wizard/games/advent.svg",
    "easter-calendar": "/assets/wizard/games/easter.svg",
    "spin-the-wheel": "/assets/wizard/games/wheel.svg",
    "advanced-spin-the-wheel": "/assets/shared/games/advanced-stw.svg",
    "survey": "/assets/wizard/games/survey.svg",
    "weekly-calendar": "/assets/wizard/games/weekly.svg",
    "monthly-calendar": "/assets/wizard/games/monthly.svg",
    "scratch-card": "/assets/wizard/games/scratch-card.svg",
    "penny-spin-the-wheel": "assets/wizard/games/penny-wheel.svg",
    "penny-penalty": "assets/wizard/games/penny-penalty.svg",
    "penny-path": "assets/shared/games/penny-path.svg",
    "penny-slot-machine": "assets/wizard/games/penny-slot-machine.svg",
    "bipa-spin-the-wheel": "assets/wizard/games/bipa-spin-the-wheel.svg",
    "spin-the-wheel-2.0": "assets/wizard/games/spin-the-wheel-2.svg",
};

export const GAME_COLORS = {
    "memory-game": "rgb(134, 134, 203)",
    "quiz-click": "rgb(221, 210, 106)",
    "quiz-swipe": "rgb(221, 187, 106)",
    "guess-the-picture": "rgb(238, 170, 142)",
    "personality-test-click": "rgb(122, 203, 170)",
    "personality-test-swipe": "rgb(140, 203, 122)",
    "advent-calendar": "rgb(198, 133, 204)",
    "spin-the-wheel": "rgb(103, 179, 220)",
    "easter-calendar": "rgb(228, 107, 110)",
    "weekly-calendar": "rgb(133, 159, 204)",
    "survey": "rgb(113, 199, 199)",
    "scratch-card": "rgb(203, 175, 134)",
    "penny-spin-the-wheel": "rgb(205, 20, 20)",
    "penny-penalty": "rgb(205, 20, 20)",
    "penny-path": "rgb(205, 20, 20)",
    "monthly-calendar": "rgb(133, 204, 140)",
    "penny-slot-machine": "rgb(205, 20, 20)",
    "bipa-spin-the-wheel": "rgb(236, 0, 140)",
    "advanced-spin-the-wheel": "rgb(220,104,156)",
};

export const FOUR_DAY_ADVENT_TYPE = "fourDay";
export const FOUR_DAYS = [1, 8, 15, 22];
export const defaultCampaignEmailDomainsV1: Partial<DefaultCampaignEmailDomain> = {
    [CampaignType.QUIZ]: "quiz-win.ch",
    [CampaignType.SURVEY]: "survey-win.ch",
    [CampaignType.MEMORY]: "memory-win.ch",
    [CampaignType.GUESS_THE_PICTURE]: "guessthepicture.ch",
    [CampaignType.PERSONALITY_TEST]: "personality-test.ch",
    [CampaignType.ADVENT_CALENDAR]: "kalender-win.ch",
    [CampaignType.EASTER_CALENDAR]: "kalender-win.ch",
    [CampaignType.WEEKLY_CALENDAR]: "kalender-win.ch",
    [CampaignType.MONTHLY_CALENDAR]: "kalender-win.ch",
    [CampaignType.SPIN_THE_WHEEL]: "spinthewheel.ch",
    [CampaignType.SCRATCH_CARD]: "scratch-card.ch",
    [CampaignType.BIPA_SPIN_THE_WHEEL]: "bipa-gewinnspiel.at",
};

export const defaultPennyCampaignEmails: DefaultPennyCampaignEmail =
    {
        [CampaignType.PENNY_SPIN_THE_WHEEL]: {
            it: "no-reply@pennygame.it",
            ro: "castigacupenny@penny.ro",
            at: "game@pennygame.at",
            hu: "jatek@pennyporgeto.hu",
        },
        [CampaignType.PENNY_SLOT_MACHINE]: {
            it: "no-reply@pennygame.it",
            ro: "castigacupenny@penny.ro",
        },
        [CampaignType.PENNY_PATH]: {
            it: "",
            ro: "",
        },
        [CampaignType.PENNY_PENALTY]: {
            it: "",
            ro: "",
        },
    };

export const ASTW_CTA_PAGES = ["win", "lose", "no-more-plays", "campaign-expired"];

export const PENNY_API_BASE_URL_MAP: Record<PennyCampaign | CampaignType.BIPA_SPIN_THE_WHEEL, string> = {
    [CampaignType.PENNY_SPIN_THE_WHEEL]: environment.pennySpinTheWheelApiBaseUrl,
    [CampaignType.PENNY_PENALTY]: environment.pennyPenaltyApiBaseUrl,
    [CampaignType.PENNY_SLOT_MACHINE]: environment.pennySlotMachineApiBaseUrl,
    [CampaignType.BIPA_SPIN_THE_WHEEL]: environment.pennySpinTheWheelApiBaseUrl,
    [CampaignType.PENNY_PATH]: environment.pennyPathApiBaseUrl,
};

export const PENNY_LEADS_EXPORT_URL_MAP: Record<PennyCampaign | CampaignType.BIPA_SPIN_THE_WHEEL, string> = {
    [CampaignType.PENNY_SPIN_THE_WHEEL]: environment.pennyLeadsExportUrl,
    [CampaignType.PENNY_PENALTY]: environment.pennyPenaltyLeadsExportUrl,
    [CampaignType.PENNY_SLOT_MACHINE]: environment.pennySlotMachineLeadsExportUrl,
    [CampaignType.BIPA_SPIN_THE_WHEEL]: environment.pennyLeadsExportUrl,
    [CampaignType.PENNY_PATH]: environment.pennyPathLeadsExportUrl,
};

export const CONFIGCAT_CLIENT = "CONFIGCAT_CLIENT";

// Some features require additional check if Admin is part of a certain company
export const INTERNAL_COMPANY_ALLOWLIST = [
    "9ff91159-30ca-11ec-95cb-e787516bc616",
    "b5b06757-3356-47a9-80e0-e6d3038fe6cf",
    "7d755d28-744d-11ec-ac7c-13f99ee6301f",
    "a88bbbcc-a282-11eb-8efc-6d63670321aa",
    "5bad004c-918e-11eb-be24-6b7050191601",
    "1256a9ba-fb65-11eb-9ae1-3ffde099d822",
    "1b66962b-7cb3-11eb-911c-7dacd934162b",
    "46997221-8808-11ec-9a22-f35ead9e6916",
    "6b2ef85c-875a-11ed-a57f-834d946ca29e",
    "a56ccaae-cc3b-46ae-8faa-239876641b12",
];
